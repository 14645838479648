<template>
  <div>
    <improved-btn-toggle
      :items="items"
      :additionalText="additionalText"
      :selection.sync="selection"
      tuplePrefixId="0D49DB0A-C1B0-E611-80C6-0CC47A695933"
      document-type="aging-bucket-range-ref"
    />
    <div>{{selection}}</div>
  </div>
</template>

<script>
export default {
  components: {
    ImprovedBtnToggle: () => import('./improved-btn-toggle')
  },
  data () {
    return {
      items: [1, 31, 61],
      additionalText: [100.15, 200.30, 300.45],
      selection: []
    }
  }
}
</script>
